<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text class="d-flex">
        <v-avatar
          rounded
          size="120"
          class="me-6"
        >
          <v-img :src="cropped"></v-img>
        </v-avatar>
        <div v-if="position==='ADMIN'||position==='MEMBER'">
          <v-btn
            color="primary"
            outlined
            class="mt-10"
            @click="updating"
          >
            Update Details
          </v-btn>
        </div>
        <v-col
          md="4"
          cols="12"
        >
          <v-text-field readonly
                        v-model="status"
                        label="Status"
                        dense
                        outlined
          ></v-text-field>
        </v-col>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field readonly
                          v-model="branch"
                          label="Branch"
                          dense
                          outlined
            ></v-text-field>
          </v-col>

          <v-col
            md="4"
            cols="12"
          >
            <v-text-field readonly
                          v-model="date_opening"
                          label="Date of Opening"
                          dense
                          outlined

            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-card
            flat
          >
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">PERSONAL INFOMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col
                md="4"
                cols="12"
              ></v-col>
              <v-row>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field readonly
                                v-model="data.last_name"
                                label="Last Name"
                                dense
                                outlined

                  ></v-text-field>
                </v-col>

                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field readonly
                                v-model="data.first_name"
                                label="First Name"
                                dense
                                outlined

                  ></v-text-field>
                </v-col>
                <v-col
                  md="4"
                  cols="12"
                >
                  <v-text-field readonly
                                v-model="data.middle_name"
                                label="Middle Name"
                                dense
                                outlined

                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field readonly
                                v-model="data.contact_no"
                                dense
                                outlined
                                label="Contact Number/s"

                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field readonly
                                v-model="data.email_address"
                                dense
                                outlined
                                label="Email Address"

                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="can_update" max-width="80%">
      <v-card
        flat
        class="pa-3 mt-2"
      >
        <v-card-text>
          <v-row>
            <v-card
              flat
            >
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">UPDATE PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" class="multi-col-validation mt-6">
                  <v-col
                    md="4"
                    cols="12"
                  ></v-col>
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="present_address"
                        dense
                        outlined
                        label="Present Address"
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-select
                        v-model="residence_is"
                        dense
                        outlined
                        label="Residence is"
                        :items="['Owned','Rented','Mortgaged','Others']"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-if="residence_is=='Others'"
                        v-model="residence_specify"
                        dense
                        outlined
                        label="Specify (if others)"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="10"
                    >
                      <v-text-field
                        v-model="permanent_address"
                        dense
                        outlined
                        label="Permanent Address"
                        :rules="rules.default_max_255_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="zip_code"
                        dense
                        outlined
                        label="Zip Code"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="contact_no"
                        dense
                        outlined
                        type="number"
                        label="Contact Number/s"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <v-text-field
                        v-model="email_address"
                        dense
                        outlined
                        label="Email Address"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-row>
        </v-card-text>
        <!-- alert -->
        <v-col cols="12" v-show="alert">
          <v-alert
            color="warning"
            text
            class="mb-0"

          >
            <div class="d-flex align-start">
              <v-icon color="warning">
                {{ icons.mdiAlertOutline }}
              </v-icon>

              <div class="ms-3">
                <p class="text-base font-weight-medium mb-1">
                  {{alert_message}}
                </p>
              </div>
            </div>
          </v-alert>
        </v-col>

        <v-col cols="12">
          <v-btn
            color="primary"
            class="me-3 mt-4"
            @click="on_update_info"
            v-if="!saving"
          >
            Save changes
          </v-btn>
          <v-progress-circular
            :size=50
            :width="5"
            color="primary"
            indeterminate
            v-else
          ></v-progress-circular>
        </v-col>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'

  const initialState = () => {
    return {
      can_update: false,
      present_address: '',
      residence_is: '',
      residence_specify: '',
      permanent_address: '',
      zip_code: '',
      contact_no: '',
      email_address: '',

      saving: false,
      alert: false,
      alert_message: '',
    }
  }
  export default {
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    props: {
      cropped: String,
      branch: String,
      status: String,
      date_opening: String,
      data: Object,
    },

    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('members_information', ['update_personal_info',]),
      updating() {
        this.key++
        this.can_update = true
        this.present_address = this.data.present_address
        this.residence_is = this.data.residence_is
        this.residence_specify = this.data.residence_specify
        this.permanent_address = this.data.permanent_address
        this.zip_code = this.data.zip_code
        this.contact_no = this.data.contact_no
        this.email_address = this.data.email_address
      },
      on_update_info() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.data.id);
          data.append('present_address', this.present_address);
          data.append('residence_is', this.residence_is);
          data.append('residence_specify', this.residence_specify);
          data.append('permanent_address', this.permanent_address);
          data.append('zip_code', this.zip_code);
          data.append('contact_no', this.contact_no);
          data.append('email_address', this.email_address);
          this.update_personal_info(data)
            .then(response => {
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.can_update = false
              this.$emit('data', this.data.last_name)
              this.saving = false
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    },
  }
</script>
